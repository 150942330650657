<template>
  <div class="py-10 bg-grey-lighter mdmax:px-4">
    <div class="paylater__content--row--desc mdmax:flex mdmax:justify-center mdmax:flex-col items-center mt-10">
      <div class="paylater__content--row--desc--title text-5xl mdmax:text-3xl font-bold mb-4 mdmax:text-center text-center text-neutral">{{ $t('installment.cta.title') }}</div>
      <div class="paylater__content--row--desc--detail text-2xl mdmax:text-sm font-semibold mb-6 ml-4 text-center text-neutral-500">
        {{ $t('installment.cta.desc') }}
      </div>
      <div class="paylater__content--row--desc--button text-center mb-14 mdmax:w-full">
        <div class="flex justify-center items-center gap-4 mdmax:flex-col mdmax:gap-2 mdmax:w-full mdmax:mx-auto">
          <a
            class="text-white border border-yellow bg-yellow py-3 px-6 rounded font-bold hover:bg-yellow-300 hover:border-yellow-300 mdmax:w-full"
            href="https://danacita.co.id/s/g2academy-submission"
            target="_blank"
            >{{ $t('installment.cta.btn1') }}</a
          >
          <a class="text-yellow border border-yellow bg-transparent py-3 px-6 rounded font-bold hover:bg-white mdmax:w-full" href="https://wa.me/6281212876886" target="_blank">CS Danacita</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ctaInstallment'
}
</script>
